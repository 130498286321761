const columnWidth = 1000


export default function PageSection({children, w, a}) {
  let x = a || w
  return <div className="w-full flex flex-col items-center">
    <div style={{width: `${x || columnWidth}px`, maxWidth: "calc(100% - 60px)"}}>
      {children}
    </div>
    
  </div>
}