import {useState, useEffect} from "react"
import PageSection from "./PageSection.js"

export default function MarketingMadeEasy({w}) {
  return <PageSection w={w}>

    <p className="mt-16 font-bold text-3xl">Marketing made easy</p>
    <div className="flex">
      <p className="mt-2 w-2/3">
        Ever feel like no matter what you try, no users are coming on to your site? The issue was never that you weren't good enough, but just that you weren't marketing the right way.
        <br/><br/>TrafficScout will help you find where your dream customers are congregating to make marketing as simple as speaking with these people. 
        <br/><br/>In reality, marketing is a solved game. If you know the right marketing channel to advertise your app in, it is possible to win everytime. Our analysis will find the perfect marketing channels for your products every single time.
      </p>
      
      <Animation/>
    </div>
    
  </PageSection>
}

const Animation = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  const steps = [
    { label: '1', content: 'Find great marketing channels', imgSrc: 'images/logo-full.png', height: "35px", alt: "TrafficScout logo"},
    { label: '2', content: 'Write a few ads', imgSrc: 'images/pen.png', height: "35px", alt: "Pen icon"},
    { label: '3', content: 'Get users', imgSrc: 'images/analytics.png', height: "125px", alt: "Analytics icon"}
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setTransitioning(true);
      setTimeout(() => {
        setCurrentStep(prevStep => (prevStep + 1) % steps.length);
        setTransitioning(false);
      }, 1000); // Duration of the animation
    }, 3000);

    return () => clearInterval(interval);
  }, [steps.length]);

  return (
    <div className="container w-full">
      <div
        className={`step ${transitioning ? 'fade-out' : ''} w-full`}
      >
        <div className="flex w-full items-center justify-center">
          <div className="label mr-3">{steps[(currentStep - 1 + steps.length) % steps.length].label}</div>
          <div className="content">{steps[(currentStep - 1 + steps.length) % steps.length].content}</div>
        </div>

        
        <img
          src={steps[(currentStep - 1 + steps.length) % steps.length].imgSrc}
          className="step-image"
          style={{height: steps[(currentStep - 1 + steps.length) % steps.length].height}}
          alt={steps[(currentStep - 1 + steps.length) % steps.length].alt}
        />
      </div>
      <div
        className={`step ${transitioning ? 'fade-in' : ''} w-full`}
      >
        <div className="flex w-full items-center justify-center">
          <div className="label mr-3">{steps[currentStep].label}</div>
          <div className="content">{steps[currentStep].content}</div>
        </div>
        <img
          src={steps[currentStep].imgSrc}
          className="step-image"
          style={{height: steps[currentStep].height}}
          alt={steps[currentStep].alt}
        />
      </div>
    </div>
  );
};
