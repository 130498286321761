import {useState} from "react"
import {Modal, Button} from "flowbite-react"

export default function DashboardNoTokensModal({setStage, auth}) {
  const [hidden, setHidden] = useState(false)

  useState(() => {
    if(!auth || auth.credits_count) {
      setHidden(true)
    }
  }, [auth])

  return <Modal show={/* auth && auth.tokens < 1 */!hidden} size="lg" popup onClose={() => {
    // setStage("dashboard")
    setHidden(true)
  }}>
    <Modal.Header className="font-medium">
      <p style={{marginLeft: "10px", marginTop: "2px", fontSize: "19px"}}>
        No credits
      </p>
    </Modal.Header>
    <p className="text-center w-full m-auto mt-1 w-4/5 ">
      You don't have any credits left. Please buy more to run analyses.
    </p>
    <Button onClick={() => setStage("pricing")} gradientDuoTone="purpleToPink" pill className="w-fit mt-4 mb-6 m-auto mt-3 mb-4">
      See pricing
    </Button>
    
  </Modal>
}
