import logo from './logo.svg';
import './App.css';
import { Textarea, TextInput, Button } from "flowbite-react";
import {useEffect, useState, useRef} from "react"
import {parse} from "best-effort-json-parser"
import { Spinner, Dropdown, Alert, Modal, Popover, FloatingLabel, Label, Checkbox } from "flowbite-react";
import { FaCaretDown, FaCaretUp, FaSort, FaFaceSmile, FaUser, FaXTwitter , FaRedditAlien, FaDiscord, FaClock, FaCircleQuestion, FaBook, FaEllipsis, FaTrashCan, FaFileExport } from "react-icons/fa6";
import { PiSidebarSimpleFill } from "react-icons/pi";
import { SimpleGauge } from "react-gauges";
import {IoReloadCircleSharp, IoSearchCircle } from "react-icons/io5"

import { apiUrl, getReach, credFetch, validateEmail, storeSessionId, getMarketingChannelsStatistics, scale, getGaugeColor, capitalizeFirstLetter, getRating, numberWithCommas } from "./util/util.js"
import ReceiveTutorialPopup from "./util/components/ReceiveTutorialPopup.js"
import DashboardNoTokensModal from "./util/components/DashboardNoTokensModal.js"
import SignInModal from "./util/components/SignInModal.js"
import Sidebar from "./util/components/Sidebar.js"
import MarketingChannel from "./util/components/MarketingChannel.js"
import PageSection from "./util/components/PageSection.js"
import Pricing from "./util/components/Pricing.js"
import Topbar from "./util/components/Topbar.js"
import MarketingMadeEasy from "./util/components/MarketingMadeEasy.js"
import ConfirmAnalysisModal from "./util/components/ConfirmAnalysisModal.js"
import ExportModal from "./util/components/ExportModal.js"

let decoder = new TextDecoder("utf-8");
let marketingChannelAnalysis









function App({startStage}) {
  const [stage, setStage] = useState(startStage || "home")

  const [productDescription, setProductDescription] = useState(null)
  const [marketingChannels, setMarketingChannels] = useState([])
  const [auth, setAuth] = useState(null)
  const [history, setHistory] = useState([])
  const [shouldAuth, setShouldAuth] = useState(false)
  const [receiveTutorialPopup, setReceiveTutorialPopup] = useState(null)
  const [tutorialEmail, setTutorialEmail] = useState("")
  const [authLoaded, setAuthLoaded] = useState(false)
  const marketingChannelsRef = useRef();

  useEffect(() => {
    //console.log("Running 1.1")
    window.onpopstate = function(event) {
      console.log("WHAT HTE FLCK")
    }
  }, [])
  useEffect(() => {
    //console.log("Running 1.2?")
    if(stage == "dashboard") {
      window.history.pushState(null, null, "/dashboard")
    }
    if(stage == "home" || stage == "start") {
      window.history.pushState(null, null, "/")
    }
    if(stage == "pricing") {
      //console.log("RUNNING?")
      window.history.pushState(null, null, "/pricing")
    }
  }, [stage])

  
  useEffect(() => {
    credFetch(apiUrl + `api/get-auth?withHistory=${stage == "dashboard"}`, {
      method: "GET"
    }).then(async response => {
      const data = await response.json()
      if(data.status == "ok") {
        setAuth(data.user)
        if(data.analyses) {
          setHistory(data.analyses)
        }
      } else {
        setShouldAuth(true)
        console.log(`AUTH FAILED (${data.reason})`)
      }
    })
  }, [])

  useEffect(() => {
    if(stage == "dashboard") {
      if(!auth) return
      credFetch(apiUrl + `api/get-history`, {
        method: "GET"
      }).then(async response => {
        const data = await response.json()
        setHistory(data.analyses)
      })
    }
  }, [stage])

  marketingChannelsRef.current = marketingChannels

  let {
    subredditsMinimumMembers,
    subredditsMaximumMembers,
    twitterMinimumFollowers,
    twitterMaximumFollowers,
    discordMinimumMembers,
    discordMaximumMembers
  } = getMarketingChannelsStatistics(marketingChannels)

  async function processGoogleCredentialResponse({credential}, goToPricing=false) {
    let response = await credFetch(apiUrl + `api/auth?withHistory=${stage == "dashboard"}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        credential
      }) 
    })
    let data = await response.json()
    if(data.status == "ok") {
      storeSessionId(data.sessionId)
      if(goToPricing) {
        setStage("pricing")
      } else {
        setStage("dashboard")
      }
      setAuth(data.user)
      if(data.analyses) {
        setHistory(data.analyses)
      }
    } else {
      alert("Auth failed. Try again later")
    }
  }

  const clickbaitMarketingChannels = [
    {
      "type": "subreddit",
      "id": "SaaS",
      "name": "SaaS",
      "preview": false,
      "activeMembers": 53,
      "members": 137150,
      "color": "#7193ff",
      "description": "Come on man, 5$ is like a cup of coffee. But the ROI is infinite",
      "title": "Come on man, 5$ is like a cup of coffee. But the ROI is infinite",
      "icon": "https://styles.redditmedia.com/t5_2qkq6/styles/communityIcon_u7ddkuay2xn21.jpg?width=256&s=9054f6d63f23825552de4bd6f31328716775e412",
      "worth": 15,
      "followerPersona": "Come on man, 5$ is like a cup of coffee. But the ROI is infinite"
    },
    {
      "type": "subreddit",
      "id": "Fortnite",
      "name": "Fortnite",
      "preview": false,
      "activeMembers": 53,
      "members": 137150,
      "color": "#7193ff",
      "description": "Discussions and useful links for SaaS owners, online business owners, and more.",
      "title": "Software As a Service Companies — The Future Of Tech Businesses",
      "icon": "https://i.ytimg.com/vi/HNW5QdNGxwA/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLDRBSzP6lUOgRGqPSTULVSsFjh9Sg",
      "worth": 14,
      "followerPersona": "Entrepreneurs and online business owners interested in SaaS products."
    },
    {
      "type": "subreddit",
      "id": "mongodb",
      "name": "mongodb",
      "preview": false,
      "activeMembers": 53,
      "members": 137150,
      "color": "#7193ff",
      "description": "Discussions and useful links for SaaS owners, online business owners, and more.",
      "title": "Software As a Service Companies — The Future Of Tech Businesses",
      "icon": "https://styles.redditmedia.com/t5_2rjwd/styles/communityIcon_vekzf3oatrs61.png",
      "worth": 14,
      "followerPersona": "Entrepreneurs and online business owners interested in SaaS products."
    }
  ]

  if(stage == "home" || stage == "start") {
    return <div className="bg-white w-full mt-4 pb-10">
      <PageSection w="1200">
        <Topbar auth={auth} setAuth={setAuth} setStage={setStage}/>
        <div className="flex mt-16 items-center">
          <div className="w-1/2 mb-8">
            {/* <div className="font-bold text-5xl" style={{lineHeight: "130%"}}>
              Find the <span className="text-purple-600">best marketing channels</span> in <span className="text-purple-600 underline decoration-dashed underline-offset-8">seconds</span>...
            </div> */}
            <div className="font-bold text-5xl" style={{lineHeight: "130%"}}>
              <span className="text-purple-600">Marketing</span> has never been <span className="text-purple-600 underline decoration-dashed underline-offset-8">easier</span>...
            </div>
            <p className="text-base mt-6">TrafficScout removes the hustle of finding a good marketing channel. We analyse your target audience and find hundreds of marketing channels you can use for marketing, all rated to help you choose. Get access to millions of potential customers today.</p>
            <div className="flex items-center mt-10">
              <Button pill gradientDuoTone="purpleToPink" className="font-bold w-56 justify-between items-center" size="xl" onClick={() => {
                if(auth) {
                  if(auth.credits_count) {
                    setStage("dashboard")
                  } else {
                    setStage("pricing")
                  }
                } else {
                  setStage("start")
                }
              }}>
                <div className="flex w-full h-full items-center justify-between">
                  <p style={{whiteSpace: "nowrap"}}>START NOW</p>
                  <img className="h-3 ml-16" src={"/images/button-arrow.png"} alt="" role="presentation"/>
                </div>
              </Button>
              <a className="ml-8 font-medium" href="#free-preview">Free preview</a>
            </div>
            
          </div>

          <div className="w-1/2 flex items-center justify-center">
            <video width="320" height="240" autoPlay loop muted className="w-3/4 focus:outline-none" style={{outline: "none", marginBottom: "-3px", clipPath: "fill-box"}}>
              <source src="https://storage.cloud.google.com/trafficscout-assets/landingpage.mp4" type="video/mp4"/>
            </video>
          </div>
        </div>
      </PageSection>
      <MarketingMadeEasy w="1200"/>
      <PageSection w="1200">
        <p className="text-center w-full font-bold text-3xl mt-14 mb-6">
          How does it work?
        </p>
        <div className="flex justify-between mb-8">
          <div className="w-52 flex flex-col items-center">
            <img className="w-52" src="/images/howitworks1.png" alt="" role="presentation"/>
            <p className="w-64 mt-2 font-medium text-center text-lg" style={{lineHeight: "120%"}}>
              1. Enter your idea
            </p>
            <p className="text-center text-base">
              With just a few clicks, TrafficScout finds and shows over <span className="special">40</span> marketing channels <span className="special">perfect</span> for your app.
            </p>
          </div>
          <div className="w-52 flex flex-col items-center">
            <img className="w-52" src="/images/howitworks3.png" alt="" role="presentation"/>
            <p className="w-64 mt-2 font-medium text-center text-lg" style={{lineHeight: "120%"}}>
              2. Target audience analysis
            </p>
            <p className="text-center text-base">
              Target audience is often <span className="special">overlooked</span> by many founders. We pay attention to it when finding and rating channels.
            </p>
          </div>
          <div className="w-52 flex flex-col items-center">
            <img className="w-52" src="/images/howitworks2.png" alt="" role="presentation"/>
            <p className="w-64 mt-2 font-medium text-center text-lg" style={{lineHeight: "120%"}}>
              3. Deep channel analysis
            </p>
            <p className="text-center text-base">
              We analyse each marketing channel and give you a <span className="special">rating</span> to allow you to pick only the <span className="special">best</span> channels for your startup.
            </p>
          </div>
          <div className="w-52 flex flex-col items-center">
            <img className="w-52" src="/images/howitworks4.png" alt="" role="presentation"/>
            <p className="w-64 mt-2 font-medium text-center text-lg" style={{lineHeight: "120%"}}>
              4. Channel statistics
            </p>
            <p className="text-center text-base">
              We <span className="special">analyse</span> the data of the marketing channels with AI to make it simpler for you to <span className="special">pick the right one</span>.
            </p>
          </div>
        </div>
      </PageSection>
      {/* <div className="w-full bg-slate-100 mt-14 mb-6 pb-10" id="free-preview">
        <PageSection a="1200" w="1200" style={{pointerEvents: "none", alignItems: "center", justifyContent: "center"}}>
          <h4 className="mt-12 text-center w-full font-bold text-3xl">Free preview</h4>
          
          {
            marketingChannels.length
              ? <div className="w-full flex justify-center">
                <div className="w-1/2 flex flex-col items-center mt-4">
                  <div className="flex w-[1000px] justify-center">
                    <div className="flex flex-col mr-3 w-[400px]">
                      <p className="font-medium mb-1 w-full ml-1">Found {marketingChannels.length} marketing channels:<span className="bg-purple-600 text-white font-bold pl-2 pr-2 pt-1 pb-1 rounded-full text-sm ml-2">PREVIEW</span></p>
                      {
                        marketingChannels.map((mc, index) => {
                          return <MarketingChannel setReceiveTutorialPopup={setReceiveTutorialPopup} isPreview={true} twitterMinimumFollowers={twitterMinimumFollowers} twitterMaximumFollowers={twitterMaximumFollowers} subredditsMinimumMembers={subredditsMinimumMembers} subredditsMaximumMembers={subredditsMaximumMembers} discordMinimumMembers={discordMinimumMembers} discordMaximumMembers={discordMaximumMembers} key={mc.type == "reddit" ? mc.name : mc.id} color={index%2==1?"white":"rgb(249 250 251)"} mc={mc}/>
                        })
                      }
                    </div>
                    <div className="flex flex-col ml-3 w-[400px]">
                      <p className="font-medium mb-1 w-full ml-1">Found 72 marketing channels<span className="bg-blue-600 text-white font-bold pl-2 pr-2 pt-1 pb-1 rounded-full text-sm ml-2 ">PRO</span></p>
                      <div className="flex-col relative" onClick={(e) => {
                        setStage("pricing")
                      }}>
                        <p style={{textAlign: "center", fontWeight: "bold", cursor: "pointer", zIndex: 100, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                          Buy the full version to get great results and better algorithms.
                        </p>
                        <div className="blur-sm select-none">
                          {
                            clickbaitMarketingChannels.map((mc, index) => {
                              return <MarketingChannel key={index} setReceiveTutorialPopup={setReceiveTutorialPopup} isPreview={"hidden"} twitterMinimumFollowers={twitterMinimumFollowers} twitterMaximumFollowers={twitterMaximumFollowers} subredditsMinimumMembers={subredditsMinimumMembers} subredditsMaximumMembers={subredditsMaximumMembers} discordMinimumMembers={discordMinimumMembers} discordMaximumMembers={discordMaximumMembers} color={index%2==1?"white":"rgb(249 250 251)"} mc={mc}/>
                            })
                          }
                        </div>
                        
                      </div>
                      
                    </div>
                  </div>
                  

                  
                  <p className="font-medium text-sm text-center mt-3">
                    <span style={{marginBottom: "0px"}}>The full version includes much more & better marketing channels, more accurate rating algorithm and more marketing channel types.<br/></span>
                    <Button style={{marginTop: "5px"}} as="a" pill gradientDuoTone="purpleToPink" className="w-fit items-center cursor-pointer inline-flex mt-3" onClick={() => {
                      if(auth) {
                        if(auth.credits_count) {
                          setStage("dashboard")
                        } else {
                          setStage("pricing")
                        }
                      } else {
                        setStage("start")
                      }
                    }}>
                      Start now
                      <img className="h-3 ml-5 mt-1" src={"/images/button-arrow.png"}/>
                    </Button>
                  </p>
                </div>
              </div> : <div className="flex mt-4 items-center justify-center">
                <div className="flex flex-col w-1/2 items-center">
                  <p className="font-medium mb-1 w-full">Product description</p>
                  <Textarea className="h-24 resize-none w-full" color="purple" placeholder="Explain what the product is and it's value. Also specify the target audience if needed." onChange={(event) => {
                    setProductDescription(event.target.value)
                  }}/>
                  <Button gradientMonochrome="purple" className="mt-3 mb-10 w-fit" pill onClick={() => {
                    credFetch(apiUrl + "api/marketing-channels-preview", {
                      method: "POST",
                      body: JSON.stringify({
                        productDescription
                      })
                    })
                      .then(response => {
                        const reader = response.body.getReader()
                        let fullString = ``



                        function pump({done, value}) {
                          let valueString = decoder.decode(new Uint8Array(value));
                          fullString = fullString + valueString

                          marketingChannelAnalysis = parse(fullString)
                          let analysis = getMarketingChannelsFromAnalysis(marketingChannelAnalysis)
                          if(analysis) {
                            console.log("wtf?")
                            setMarketingChannels(analysis.mc)
                          }
                          

                          if(done) {

                            
                            if(analysis.mc.length) {

                              loadAnalysis(analysis.categories.redditChannels, marketingChannelsRef, setMarketingChannels, productDescription, "subreddit")
                              loadAnalysis(analysis.categories.twitterChannels, marketingChannelsRef, setMarketingChannels, productDescription, "twitter")
                              loadAnalysis(analysis.categories.discordChannels, marketingChannelsRef, setMarketingChannels, productDescription, "discord")
                            
                            }
                            
                            return
                          }

                          return reader.read().then(pump)
                        }
                        reader.read().then(pump)
                      })
                  }}>
                    Find marketing channels
                  </Button>
                </div>
              </div>
          }
        </PageSection>

        
      </div> */}
      
      <SignInModal shouldAuth={shouldAuth} auth={auth} setStage={setStage} stage={stage} processGoogleCredentialResponse={processGoogleCredentialResponse}/>
      <ReceiveTutorialPopup {...{setReceiveTutorialPopup, receiveTutorialPopup, tutorialEmail, setTutorialEmail, auth}}/>
      

      {/* <div className="w-full bg-slate-100 mt-14 mb-6">
        <PageSection style={{pointerEvents: "none"}}>
          <img src="/images/questions.png" className="w-full mt-10" style={{pointerEvents: "none", userSelect: "none"}}/>
          <p className="font-bold w-full text-center text-lg mb-8">DOES THIS SOUND LIKE YOU?</p>
        </PageSection>
        
      </div> */}

    </div>
  } else if(stage == "dashboard") {
    return <>
      <AppMain setReceiveTutorialPopup={setReceiveTutorialPopup} stage={stage} setStage={setStage} history={history} setHistory={setHistory} auth={auth} setAuth={setAuth}/>
      <SignInModal shouldAuth={shouldAuth} auth={auth} setStage={setStage} stage={stage} processGoogleCredentialResponse={processGoogleCredentialResponse}/>
      <ReceiveTutorialPopup {...{setReceiveTutorialPopup, receiveTutorialPopup, tutorialEmail, setTutorialEmail, auth}}/>
    </>
  } else if(stage == "pricing" || stage == "pricing-start") {
    return <>
      <Pricing auth={auth} setAuth={setAuth} setStage={setStage}/>
      <SignInModal shouldAuth={shouldAuth} auth={auth} setStage={setStage} stage={stage} processGoogleCredentialResponse={processGoogleCredentialResponse}/>
    </>
  }
}



let currentLoadingMode = null // either "original" or "restart"

function AppMain({history, setHistory, auth, setAuth, stage, setStage, setReceiveTutorialPopup}) {
  const [productDescription, setProductDescription] = useState(null)
  const [marketingChannels, setMarketingChannels] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [marketingIsLoading, setMarketingIsLoading] = useState(false)
  const [sortBy, setSortBy] = useState("Rating")
  const [isPainkiller, setIsPainkiller] = useState(true)
  let [targetAudience, setTargetAudience] = useState(null)
  const [sidebarOpened, setSidebarOpened] = useState(true)
  const [confirmAnalysisModalOpened, setConfirmAnalysisModalOpened] = useState(false)
  const [exportModalOpened, setExportModalOpened] = useState(false)

  const [selectedAnalysis, setSelectedAnalysis] = useState(null)
  const [analysisEdited, setAnalysisEdited] = useState(false)
  
  const marketingChannelsRef = useRef();

  marketingChannelsRef.current = marketingChannels



  let {
    subredditsMinimumMembers,
    subredditsMaximumMembers,
    twitterMinimumFollowers,
    twitterMaximumFollowers,
    discordMinimumMembers,
    discordMaximumMembers,
    twitterCommunityMinimumMembers,
    twitterCommunityMaximumMembers
  } = getMarketingChannelsStatistics(marketingChannels)

  let sortedMarketingChannels
  if(sortBy == "Type") {
    // console.log(marketingChannels)
    sortedMarketingChannels = marketingChannels
  } else if(sortBy == "Rating") {
    sortedMarketingChannels = marketingChannels.toSorted((a, b) => {
      let aRating = getRating(a, subredditsMinimumMembers, subredditsMaximumMembers, twitterMinimumFollowers, twitterMaximumFollowers, discordMinimumMembers, discordMaximumMembers, twitterCommunityMinimumMembers, twitterCommunityMaximumMembers)
      let bRating = getRating(b, subredditsMinimumMembers, subredditsMaximumMembers, twitterMinimumFollowers, twitterMaximumFollowers, discordMinimumMembers, discordMaximumMembers, twitterCommunityMinimumMembers, twitterCommunityMaximumMembers)
      if(a.isCompany || a.type == "blog") {
        return 1
      }
      if(b.isCompany || a.type == "blog") {
        return -1
      }
      return bRating-aRating
    })
  } else if(sortBy == "Reach") {
    sortedMarketingChannels = marketingChannels.toSorted((a, b) => {
      let aRating = getReach(a)
      let bRating = getReach(b)
      if(a.isCompany) {
        return 1
      }
      if(b.isCompany) {
        return -1
      }
      return bRating - aRating
    })
  }

  // remove duplicates from sortedMarketingChannels
  sortedMarketingChannels = sortedMarketingChannels.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.id === value.id
    ))
  )

  //console.log(sortedMarketingChannels)

  let fullString = ``
  function pump(done, value, reader) {
    let valueString = decoder.decode(new Uint8Array(value));
    fullString = fullString + valueString

    marketingChannelAnalysis = parse(fullString)
    let analysis = getMarketingChannelsFromAnalysis(marketingChannelAnalysis)
    targetAudience = marketingChannelAnalysis?.communities?.targetAudience
    if(marketingChannelAnalysis?.credits_count) {
      setAuth({
        ...auth,
        credits_count: marketingChannelAnalysis.credits_count
      })
    }
    setTargetAudience(targetAudience)
    setMarketingChannels(analysis.mc)


    if(done) {
      setIsLoading(false)

      
      if(analysis.mc.length) {
        setMarketingIsLoading(true)

        Promise.all([
          loadAnalysis(analysis.categories.redditChannels, marketingChannelsRef, setMarketingChannels, productDescription, "subreddit"),
          loadAnalysis(analysis.categories.twitterChannels, marketingChannelsRef, setMarketingChannels, productDescription, "twitter"),
          loadAnalysis(analysis.categories.discordChannels, marketingChannelsRef, setMarketingChannels, productDescription, "discord"),
          loadAnalysis(analysis.categories.twitterCommunitiesChannels, marketingChannelsRef, setMarketingChannels, productDescription, "twitter-community"),
          loadAnalysis(analysis.categories.blogChannels, marketingChannelsRef, setMarketingChannels, productDescription, "blog")
        ])
          .then(async () => {
            let originalAnalysis
            if(currentLoadingMode == "original") {
              originalAnalysis = null
            } else {
              const selectedAnalysisObject = history.find(x => x.id == selectedAnalysis)
              originalAnalysis = selectedAnalysisObject.originalAnalysis || selectedAnalysisObject.id
            }

            const response = await credFetch(apiUrl + "api/add-to-history", {
              method: "POST",
              body: JSON.stringify({
                analysis: {
                  productDescription,
                  marketingChannels: marketingChannelsRef.current,
                  targetAudience,
                  original: currentLoadingMode == "original",
                  originalAnalysis: originalAnalysis
                }
              })
            })
            setMarketingIsLoading(false)
            const data = await response.json()
            if(data.status == "ok") {
              const analysis = {
                id: data.id,
                productDescription,
                marketingChannels: marketingChannelsRef.current,
                targetAudience,
                original: currentLoadingMode == "original",
                originalAnalysis: originalAnalysis
              }
              setSelectedAnalysis(data.id)
              setHistory([
                ...history,
                analysis
              ])
              console.log("Added to history successfully")
            } else {
              console.log("Failed to add to history")
            }
          })
      }

      
      return
    }

    return reader.read().then(({done, value}) => {
      pump(done, value, reader)
    })
  }

  async function restartAnalysis(id) {
    fullString = ""
    currentLoadingMode = "restart"
    const previousAnalysis = history.find(h => {return h.id == id})

    if(!previousAnalysis?.id) return alert("No analysis existing to restart. Reload the page and try again later.")

    setSelectedAnalysis(true)
    setIsLoading(true)

    fetch(apiUrl + "api/restart-analysis", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: previousAnalysis.id
      })
    }).then(response => {
      const reader = response.body.getReader()
        
      console.log("little reader", reader)

      reader.read().then(({done, value}) => {
        pump(done, value, reader)
      })
    })
  }

  function startAnalysis() {
    fullString = ""
    currentLoadingMode = "original"
    setAnalysisEdited(false)
    setSelectedAnalysis(true)


    setIsLoading(true)
    credFetch(apiUrl + "api/marketing-channels", {
      method: "POST",
      body: JSON.stringify({
        productDescription
      })
    })
      .then(response => {
        const reader = response.body.getReader()
        
        reader.read().then(({done, value}) => {
          pump(done, value, reader)
        })
      })
  }

  return (
    <div className="flex w-full h-full" style={{height: "100vh"}}>
      <Sidebar {...{selectedAnalysis, setSelectedAnalysis, analysisEdited, setAnalysisEdited}} setTargetAudience={setTargetAudience} setMarketingChannels={setMarketingChannels} setProductDescription={setProductDescription} history={history} sidebarOpened={sidebarOpened} setSidebarOpened={setSidebarOpened} setHistory={setHistory}/>
      <div className="bg-white w-full" style={{overflow: "auto", position: "relative"}}>
        <div style={{position: "absolute", top: "10px", left: "10px"}} className="flex items-center">
          {
            sidebarOpened
              ? null
              : <PiSidebarSimpleFill className="cursor-pointer w-8 h-8" onClick={() => {
                setSidebarOpened(!sidebarOpened)
              }}/>
          }
        </div>
        
        <PageSection>
          <div className="mt-8" style={{position: "relative", top: "5px"}}>
            <Topbar auth={auth} setAuth={setAuth} setStage={setStage} showCredits={true}/>
          </div>
          
          {/* <div className="w-full flex justify-between mt-10">
            <a href="../">
              <img src="/images/logo-full.png" className="w-48 cursor-pointer"/>
            </a>
            {
              auth
                ? <Popover trigger="click" placement="bottom" content={<div className="pl-3 pr-3 flex flex-col items-center">
                <p className="text-center mt-2 font-medium text-lg">
                  Welcome, {auth.given_name || auth.name || auth.email}
                </p>
                <Button size="sm" className="mt-2 mb-2" color="red" pill onClick={() => {
                  credFetch(apiUrl + "api/logout", {
                    method: "GET",
                  })
                  setAuth(null)
                  window.location.reload()
                }}>Log out</Button>
              </div>}>
                <img src={auth.picture} className="cursor-pointer ml-5 h-10 aspect-square rounded-full bg-white border border-black"/>
              </Popover>
              : null
            }
            
          </div> */}
          <h1 className="mt-8 w-full text-center mt-4 mb-4 text-2xl font-semibold">Get marketing channels</h1>
          <Popover trigger="hover" aria-labelledby="product-description" content={
            <div className="w-64 text-sm text-gray-500 dark:text-gray-400">
              <div className="px-3 py-2">
                <p>A good product description should highlight the problems it solves, why it solves that problem, etc...</p>
              </div>
            </div> 
          }>
            <div className="flex items-center cursor-pointer w-fit">
              <p className="font-medium mb-1">Product description</p>
              <FaCircleQuestion className="opacity-80 ml-1 mb-1"/>
            </div>
            
          </Popover>
          <Textarea id="product-description-input" disabled={isLoading || marketingIsLoading} value={productDescription} className="h-24 resize-none" color="purple" placeholder="Explain what the product is and it's value. Also specify the target audience if applicable." onChange={(event) => {
            setAnalysisEdited(true)
            setProductDescription(event.target.value)
          }}/>
          <div className="flex mt-3 items-center">
            <Button disabled={isLoading || marketingIsLoading} gradientMonochrome={(!analysisEdited && selectedAnalysis) ? "pink" : "purple"} className="items-center flex" onClick={() => {
              
              
              if(!analysisEdited && selectedAnalysis) {
                setConfirmAnalysisModalOpened("restart")
              } else {
                if(!productDescription || productDescription.length < 20) {
                  return alert("The product description needs to be at least 20 characters long!")
                }
                setConfirmAnalysisModalOpened(true)
              }
              
            }} pill>
              {
                (!analysisEdited && selectedAnalysis)
                  ? <>
                    <IoReloadCircleSharp className="h-5 w-5 mr-2"/>
                    <span>Restart analysis (free)</span>
                  </>
                  : <>
                    <IoSearchCircle className="h-5 w-5 mr-2"/>
                    <span>Find marketing channels</span>
                  </>
              } 
            </Button>
            {
              (analysisEdited && selectedAnalysis)
                ? <button className="text-black flex ml-5 items-center text-sm select-none cursor-pointer" onClick={() => {
                  const analysis = history.find(h => {return h.id == selectedAnalysis})
                  if(analysis) {
                    setProductDescription(analysis.productDescription)
                    setMarketingChannels(analysis.marketingChannels)
                    setTargetAudience(analysis.targetAudience)
                    setAnalysisEdited(false)
                  } else {
                    alert("Can't find this analysis. Perhaps it was deleted?")
                  }
                }}>
                  <IoReloadCircleSharp className="h-5 w-5 mr-2"/>
                  Reset to original search
                </button>
                : null
            }
          </div>
          
          {
            targetAudience
              ? <p className="text-base mt-6 flex items-center">
                <FaCircleQuestion className="mr-2"/>
                <span className="font-semibold" style={{whiteSpace: "nowrap"}}>Who is this for?</span>
                <span className="ml-2">{targetAudience}</span>
              </p>
              : null
          }
          

          
          <div className="flex flex-row mt-8 justify-between">
            
            {
              marketingChannels.length
                ? <>
                  <div className="flex items-center">
                    <p>Found {marketingChannels.length} marketing channels</p>
                    <Button gradientMonochrome="purple" size="xs" pill className="ml-3" onClick={() => {
                      setExportModalOpened(true)
                    }}>
                      <div className="flex items-center">
                        <FaFileExport className="mr-1 mb-[1px]"/>
                        Export
                      </div>
                      
                    </Button>
                  </div>
                  <div className="flex items-center">
                    <FaSort />
                    <p className="mr-3 ml-1">Sort by:</p>
                    <Dropdown label={sortBy} inline>
                      <Dropdown.Item onClick={() => {
                        setSortBy("Type")
                      }}>Type</Dropdown.Item>
                      <Dropdown.Item onClick={() => {
                        setSortBy("Rating")
                      }}>Rating</Dropdown.Item>
                      <Dropdown.Item onClick={() => {
                        setSortBy("Reach")
                      }}>Reach</Dropdown.Item>
                    </Dropdown>
                  </div>
                  
                </>
                : null
            }
            
            
            
          </div>
          <div className="mt-6 mb-12">
            {
              sortedMarketingChannels.map((mc, index) => {                
                return <MarketingChannel setReceiveTutorialPopup={setReceiveTutorialPopup} twitterMinimumFollowers={twitterMinimumFollowers} twitterMaximumFollowers={twitterMaximumFollowers} subredditsMinimumMembers={subredditsMinimumMembers} subredditsMaximumMembers={subredditsMaximumMembers} discordMinimumMembers={discordMinimumMembers} discordMaximumMembers={discordMaximumMembers} key={mc.type == "reddit" ? mc.name : mc.id} color={index%2==1?"white":"rgb(249 250 251)"} mc={mc} {...{twitterCommunityMinimumMembers, twitterCommunityMaximumMembers}}/>
              })
            }
          </div>
          
        </PageSection>
      </div>
      <DashboardNoTokensModal auth={auth} setStage={setStage}/>
      <ConfirmAnalysisModal selectedAnalysis={selectedAnalysis} restartAnalysis={restartAnalysis} startAnalysis={startAnalysis} confirmAnalysisModalOpened={confirmAnalysisModalOpened} setConfirmAnalysisModalOpened={setConfirmAnalysisModalOpened}/>
      <ExportModal exportModalOpened={exportModalOpened} setExportModalOpened={setExportModalOpened} marketingChannels={marketingChannels} targetAudience={targetAudience} {...{subredditsMinimumMembers, subredditsMaximumMembers, twitterMinimumFollowers, twitterMaximumFollowers, discordMinimumMembers, discordMaximumMembers, twitterCommunityMinimumMembers, twitterCommunityMaximumMembers}}/>
    </div>
    
  );
}




function loadAnalysis(channels, marketingChannelsRef, setMarketingChannels, productDescription, type="subreddit") {
  return new Promise(async function(resolve, reject) {
    if(channels) {

      let response = await fetch(apiUrl + `api/${type == "subreddit" ? "reddit" : type}-channels-rating?productDescription="${productDescription}"`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(channels)
      })
      const reader = response.body.getReader()
      let fullString = ""
      function pump({done, value}) {
        let valueString = decoder.decode(new Uint8Array(value));
        fullString = fullString + valueString
        let ratingsAnalysis = parse(fullString)
  
        ratingsAnalysis = ratingsAnalysis.output || []
        if(!done) {
          ratingsAnalysis.pop()
          //console.log(redditRatingsAnalysis)
        } else {
          
        }
  
        

        const newMarketingChannels = marketingChannelsRef.current.map(channel => {
          if(channel.type == type) {
            console.log(channel, ratingsAnalysis)
            //console.log(ratingsAnalysis.find(rating => {return rating.id == channel.id}))
            return {
              ...channel,
              ...(ratingsAnalysis.find(rating => {return channel.type == "blog" ? rating.url == channel.url : rating.id == channel.id}))
            }
          } else {
            return channel
          }
        })
        
        setMarketingChannels(newMarketingChannels)
        console.log(newMarketingChannels)

        if(!done) {
          return reader.read().then(pump)
        } else {
          resolve()
        }
      }
      reader.read().then(pump)
  
    }
  
  })
}










function getMarketingChannelsFromAnalysis(analysis) {
  if(!analysis) return []

  //console.log(analysis)

  //console.log(analysis)

  let redditChannels = []
  let twitterChannels = []
  let discordChannels = []
  let twitterCommunitiesChannels = []
  let blogChannels = []

  if(!analysis.subreddits) {
    if(typeof analysis?.communities?.reddit == "string") {

      redditChannels = [{
        type: "subreddit",
        name: analysis.communities.reddit,
        preview: true,
      }]
    } else {
      redditChannels = analysis?.communities?.reddit?.map(subreddit => {
        return {
          type: "subreddit",
          name: subreddit.name,
          preview: true,
        }
      }) || []
    }
    
  } else {
    redditChannels = analysis?.subreddits.filter(s => {
      return !!s && !s?.ignore
    }).map(subreddit => {
      return {
        type: "subreddit",
        id: subreddit.displayName,
        name: subreddit.displayName,
        preview: false,
        activeMembers: subreddit.activeMembers,
        members: subreddit.members,
        color: subreddit.color,
        description: subreddit.description,
        title: subreddit.title,
        icon: subreddit.icon
      }
    })
  }

  if(analysis.twitterAccounts) {
    twitterChannels = analysis?.twitterAccounts.map(twitterAccount => {
      return {
        type: "twitter",
        id: twitterAccount.userId,
        icon: twitterAccount.avatar,
        description: twitterAccount.biography,
        followersCount: twitterAccount.followersCount,
        verified: twitterAccount.isBlueVerified,
        name: twitterAccount.name,
        username: twitterAccount.username,
        tweetsCount: twitterAccount.tweetsCount,
        url: twitterAccount.url,
        website: twitterAccount.website,
        canDm: twitterAccount.canDm
      }
    })
  }

  if(analysis.discordServers) {
    discordChannels = analysis?.discordServers.map(discordServer => {
      return {
        type: "discord",
        id: discordServer.id,
        description: discordServer.description,
        members: discordServer.members,
        activeMembers: discordServer.activeMembers,
        locale: discordServer.locale,
        category: discordServer.category,
        keywords: discordServer.keywords,
        verified: discordServer.verified,
        name: discordServer.name,
        vanityUrlCode: discordServer.vanityUrlCode,
        icon: `https://cdn.discordapp.com/icons/${discordServer.id}/${discordServer.icon}.jpg?size=256`
      }
    })
    //console.log(discordChannels, "DISCORD")
  }

  if(analysis.twitterCommunities) {
    twitterCommunitiesChannels = analysis?.twitterCommunities.map(twitterCommunity => {
      return {
        ...twitterCommunity,
        type: "twitter-community"
      }
    })
  }
  
  if(analysis.blogs) {
    blogChannels = analysis?.blogs.map(blog => {
      return {
        ...blog,
        id: generateId(),
        type: "blog",
        icon: `https://www.google.com/s2/favicons?domain=${blog.url}&sz=256`
      }
    })
  }

  //console.log(redditChannels)

  return {mc: [...redditChannels, ...twitterChannels, ...discordChannels, ...twitterCommunitiesChannels, ...blogChannels], categories: {redditChannels, twitterChannels, discordChannels, twitterCommunitiesChannels, blogChannels}}
}

function generateId() {
  return "id" + Math.random().toString(16).slice(2)
}

export default App;
