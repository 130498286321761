import {useState} from "react"
import {Modal, Button} from "flowbite-react"

export default function ConfirmAnalysisModal({restartAnalysis, selectedAnalysis, confirmAnalysisModalOpened, setConfirmAnalysisModalOpened, startAnalysis}) {
  return <Modal show={confirmAnalysisModalOpened} size="lg" popup onClose={() => {
    setConfirmAnalysisModalOpened(false)
  }}>
    <Modal.Header className="font-medium pl-3">
      Confirm search
    </Modal.Header>
    <div className="flex flex-col items-center">
      {
        confirmAnalysisModalOpened == "restart"
          ? <p className="text-center w-4/5 mt-2">
            This follow-up search will not cost any credits 🙂<br/>
            Just press the button below to confirm you want to restart with the same product:
          </p>
          : <p className="text-center w-4/5 mt-2">
            This search will cost 1 credit. You will be able to restart this search for free only if you use the same product description, so make sure it is complete enough.
          </p>
      }
      <Button className="success w-fit mb-4 mt-6" pill color="purple" onClick={() => {
        if(confirmAnalysisModalOpened == "restart") {
          setConfirmAnalysisModalOpened(false)
          restartAnalysis(selectedAnalysis)
        } else {
          setConfirmAnalysisModalOpened(false)
          startAnalysis()
        }
        
      }}>Confirm</Button>

    </div>
  </Modal>
}