import {Modal} from "flowbite-react"
import { GoogleLogin } from '@react-oauth/google';


export default function SignInModal({auth, shouldAuth, processGoogleCredentialResponse, stage, setStage}) {
  let show = false
  if(stage == "start" || stage == "pricing-start") {
    show = true
  }
  if(stage == "dashboard") {
    if(!auth && shouldAuth) {
      show = true
    }
  }

  return <Modal show={show} size="lg" popup onClose={() => {
    if(stage == "pricing-start") {
      setStage("pricing")
    } else {
      setStage("home")
    }
  }}>
    <Modal.Header className="font-medium">
      <p style={{marginLeft: "10px", marginTop: "2px", fontSize: "19px"}}>
        Sign in to TrafficScout
      </p>
    </Modal.Header>
    <p className="text-center w-full mt-2">
      To continue, please sign in with Google:
    </p>
    <div className="w-full flex justify-center mt-4 mb-6">
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          processGoogleCredentialResponse(credentialResponse, stage == "pricing-start")
        }}
        onError={() => {
          console.log('Login Failed');
        }}
        shape="pill"
      />
    </div>
  </Modal>
}