import PageSection from "./PageSection.js"
import Faq from "react-faq-component";
import ReceiveTutorialPopup from "./ReceiveTutorialPopup"
import {useState} from "react"

export default function FAQ({w, auth}) {
  const [receiveTutorialPopup, setReceiveTutorialPopup] = useState(false)
  const [tutorialEmail, setTutorialEmail] = useState("")

  return <PageSection w={w}>

    <p className="mt-16 font-bold text-3xl">FAQ</p>
    <Faq
      data={{
        rows: [
          {
            title: "What is the difference between a product and an analysis?",
            content: <p>A product can have multiple analyses. You can run multiple analyses for free (as long as it is done for the same product). To use it for multiple products, you will have to buy multiple credits.</p>
          },
          {
            title: "How many results does one analysis give?",
            content: <p>The amount of results that TrafficScout finds depends on the niche of your product. TrafficScout should return from 40 to 84 marketing channels, but you can run multiple analyses for free (for the same product) to get more results.</p>
          },
          {
            title: "What types of marketing channels can TrafficScout give?",
            content: <p>The following types of channels can be output by TrafficScout: Subreddits, X accounts, X communities, Discord servers, and we also search the internet to find 10 blogs fitting your product.</p>
          },
          {
            title: "What is the refund policy?",
            content: <p>If you're ever unsatisfied with your results from TrafficScout search, you can always request a refund by sending an email to <a href="mailto:asemenov.ca@gmail.com" target="_blank" className="text-blue-500">support</a> and we'll respond in less than 24 hours.</p>
          },
          {
            title: "How can I use marketing channels for advertising?",
            content: <p>There's a guide written by me as well as other founders, mainly from Build In Public. You can receive it by clicking <a target="_blank" className="cursor-pointer text-blue-500" onClick={() => {
              setReceiveTutorialPopup(true)
            }}>this</a>. It will be in the inbox of the email you wrote (free)</p>
          }
        ]
      }}
    />
    <div className="mb-24"></div>
    <ReceiveTutorialPopup receiveTutorialPopup={receiveTutorialPopup} setReceiveTutorialPopup={setReceiveTutorialPopup} tutorialEmail={tutorialEmail} setTutorialEmail={setTutorialEmail} auth={auth}/>
  </PageSection>
}