const apiUrl = process.env.REACT_APP_API_URL//"http://localhost:8080/"
console.log(apiUrl)

function getReach(mc) {
  if(mc.type == "twitter") {
    return mc.followersCount
  } else {
    return mc.members
  }
}

function credFetch(url, data) {
  return fetch(url, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "Session-ID": localStorage.getItem("sessionId"),
      ...data?.headers
    },
    ...data,
  })
}

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function storeSessionId(sessionId) {
  localStorage.setItem("sessionId", sessionId)
  document.cookie = `session.sid=${sessionId}`
}

function getMarketingChannelsStatistics(marketingChannels) {
  let redditMarketingChannels = marketingChannels.filter(mc => {
    return mc.type == "subreddit"
  })
  let twitterMarketingChannels = marketingChannels.filter(mc => {
    return mc.type == "twitter"
  })
  let discordMarketingChannels = marketingChannels.filter(mc => {
    return mc.type == "discord"
  })
  let twitterCommunityMarketingChannels = marketingChannels.filter(mc => {
    return mc.type == "twitter-community"
  })
  let subredditsMinimumMembers = redditMarketingChannels.reduce((previous, current) => {
    return Math.min(previous, current.members)
  }, Infinity)
  let subredditsMaximumMembers = redditMarketingChannels.reduce((previous, current) => {
    return Math.max(previous, current.members)
  }, 0)
  let twitterMinimumFollowers = twitterMarketingChannels.reduce((previous, current) => {
    return Math.min(previous, current.followersCount)
  }, Infinity)
  let twitterMaximumFollowers = twitterMarketingChannels.reduce((previous, current) => {
    return Math.max(previous, current.followersCount)
  }, 0)
  let discordMinimumMembers = discordMarketingChannels.reduce((previous, current) => {
    return Math.min(previous, current.members)
  }, Infinity)
  let discordMaximumMembers = discordMarketingChannels.reduce((previous, current) => {
    return Math.max(previous, current.members)
  }, 0)
  let twitterCommunityMinimumMembers = twitterCommunityMarketingChannels.reduce((previous, current) => {
    return Math.min(previous, current.members)
  }, Infinity)
  let twitterCommunityMaximumMembers = twitterCommunityMarketingChannels.reduce((previous, current) => {
    return Math.max(previous, current.members)
  }, 0)

  console.log(twitterCommunityMaximumMembers)

  return {
    redditMarketingChannels,
    twitterMarketingChannels,
    discordMarketingChannels,
    subredditsMinimumMembers,
    subredditsMaximumMembers,
    twitterMinimumFollowers,
    twitterMaximumFollowers,
    discordMinimumMembers,
    discordMaximumMembers,
    twitterCommunityMinimumMembers,
    twitterCommunityMaximumMembers
  }
}

function scale (number, inMin, inMax, outMin, outMax) {
  return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
}

function getGaugeColor(r) {
  return `hsl(${r/100*133/1.3}, 90%, 41%)`
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getRating(mc, subredditsMinimumMembers, subredditsMaximumMembers, twitterMinimumFollowers, twitterMaximumFollowers, discordMinimumMembers, discordMaximumMembers, twitterCommunityMinimumMembers, twitterCommunityMaximumMembers) {
  if(mc.type == "subreddit") {
    console.log(mc.members, subredditsMinimumMembers - 1, subredditsMaximumMembers + 1, -1, 1)
    return Math.max(1, 10 * (mc.worth + scale(mc.members, subredditsMinimumMembers - 1, subredditsMaximumMembers + 1, -1, 1))) || 0
  } else if(mc.type == "twitter") {
    return Math.max(1, 10 * (mc.worth + scale(mc.followersCount, twitterMinimumFollowers - 1, twitterMaximumFollowers + 1, -1, 1))) || 0
  } else if(mc.type == "discord") {
    return Math.max(1, 10 * (mc.worth + scale(mc.members, discordMinimumMembers - 1, discordMaximumMembers + 1, -1, 1))) || 0
  } else if(mc.type == "twitter-community") {
    let s = Math.max(1, 10 * (mc.worth + scale(mc.members, twitterCommunityMinimumMembers - 1, twitterCommunityMaximumMembers + 1, -1, 1))) || 0
    return s
  } else if(mc.type == "blog") {
    return Math.max(1, 10 * (mc.worth)) || 0
  }
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


let timeConversionData = [
	["second", "seconds", 1000], 
	["minute", "minutes", 60], 
	["hour", "hours", 60], 
	["day", "days", 24], 
	["week", "weeks", 7],
	["month", "months", 4.34524],
	["year", "years", 12],
	["decade", "decades", 10],
	["century", "centuries", 10]
]
function convertTimeFromMS(time) {
	time = Math.max(time, 1000)
	let timeConversionDataIndex = 0
	while(time / timeConversionData[timeConversionDataIndex][2] >= 1) {
		time = time / timeConversionData[timeConversionDataIndex][2]
		timeConversionDataIndex++
		if(timeConversionDataIndex >= timeConversionData.length) {
			break
		}
	}
	let t = timeConversionData[timeConversionDataIndex - 1][1]
	let timeNumber = Math.floor(time)
    if(timeNumber == 1) {
        t = timeConversionData[timeConversionDataIndex - 1][0]
    }
	return timeNumber + " " + t
}
const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
function timeToHumanReadableFormat(time) {
	let date = new Date(time)
	return date.getDay() + " " + months[date.getMonth()] + ", " + date.getFullYear()
}

const second = 1000
const minute = second * 60
const hour = minute * 60
const day = hour * 24
const week = day * 7
const month = week * 4.34524
const year = month * 12
const decade = year * 10
const century = decade * 10


export {apiUrl, getReach, credFetch, validateEmail, storeSessionId, getMarketingChannelsStatistics, scale, getGaugeColor, capitalizeFirstLetter, getRating, numberWithCommas, timeToHumanReadableFormat, convertTimeFromMS}