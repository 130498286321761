import PageSection from "./PageSection.js"
import Topbar from "./Topbar.js"
import {Button, Popover} from "flowbite-react"
import "./Animation.css"
import MarketingMadeEasy from "./MarketingMadeEasy.js"
import FAQ from "./FAQ.js"
import {credFetch, apiUrl} from "../util.js"
import { FaCircleCheck } from "react-icons/fa6";
import { AiFillQuestionCircle } from "react-icons/ai";

export default function Pricing({setStage, auth, setAuth}) {
  return <div className="mt-6">
    <PageSection>
      <Topbar setStage={setStage} auth={auth} setAuth={setAuth} showCredits={true}/>
      <h2 className="text-center w-full font-bold mt-12" style={{fontSize: "43px"}}>LIMITED TIME DISCOUNT: <span className="text-purple-700">50% OFF</span> ALL PLANS</h2>
      <p className="text-center font-medum mt-2 text-lg">We use fair <span className="font-semibold">usage-based</span> pricing. Pay <span className="font-semibold">only</span> for how many products you need (pay as you go)</p>
      <Popover trigger="hover" content={
        <div className="w-48 flex flex-col items-center pl-4 pr-4 text-center text-sm bg-white border-black border rounded-lg " placement="bottom" style={{zIndex: 10000}}>
          <p>One credit will let you create run as many analyses as you need for 1 product description (you'll have to use more credits to run analyses for a different product).</p>
        </div>
      }>
        <p className="text-center font-medum mt-2 text-lg cursor-pointer select-none flex items-center justify-center">1 credit = 1 product (unlimited analyses <AiFillQuestionCircle className="inline ml-1" size="20"/>)</p>
      </Popover>
      
      <div className="flex justify-between mt-8">
        <PricingPlan auth={auth} setStage={setStage} discounted="49.99$" price="24.99$" tag="BEST VALUE" credits={10} description="For multiple projects"/>
        <PricingPlan auth={auth} setStage={setStage} discounted="19.99$" price="9.99$" credits={3} description="For a bullet-proof marketing plan"/>
        <PricingPlan auth={auth} setStage={setStage} outline discounted="9.99$" price="4.99$" credits={1} description="For a working marketing plan"/>
      </div>

    </PageSection>
    <hr className="mt-20" style={{border: "1px solid darkgrey"}}/>
    <MarketingMadeEasy/>
    <FAQ auth={auth}/>
  </div>
}


function PricingPlan({tag, credits, description, discounted, price, outline, auth, setStage}) {
  return <div className="flex flex-col pl-6 pr-6 w-[320px] border-slate-500 border-2 rounded-xl min-h-12">
    <div className="h-[30px] w-full flex justify-center mt-[-15px] mb-2">
      {
        tag
          ? <div className="w-3/5 h-full bg-slate-500 rounded-full text-white text-center flex items-center justify-center font-medium">
            { tag }
          </div>
          : null
      }
    </div>
    <p className="font-medium text-2xl">{credits} {credits == 1 ? "Credit" : "Credits"}</p>
    <p className="font-medium" style={{fontSize: "14px", opacity: 0.9, marginTop: "-3px"}}>{description}</p>
    <div className="mt-2 flex text-2xl font-semibold">
      <p className="line-through opacity-80">{discounted}</p>
      <p className="text-green-500 ml-3">{price}</p>
    </div>
    <Button outline={outline} gradientMonochrome="purple" pill className="mt-4 mb-3" size="sm" onClick={() => {
      if(!auth) {
        return setStage("pricing-start")
      }
      
      credFetch(apiUrl + "api/create-checkout-session", {
        method: "POST",
        body: JSON.stringify({
          creditsCount: credits
        }),
      }).then(response => {
        response.json().then(data => {
          window.location = data.url
        })
      })
    }}>
      Purchase
    </Button>
    <div className="flex items-center ml-2 mb-4">
      <FaCircleCheck/>
      <p className="ml-2">Unlimited analyses</p>
    </div>
  </div>
}
