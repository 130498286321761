import {useState} from "react"
import {Popover, Button} from "flowbite-react"
import {credFetch, apiUrl} from "../util.js"
import {FaTrashCan, FaAngleDown, FaAngleUp} from "react-icons/fa6"
import {timeToHumanReadableFormat, convertTimeFromMS} from "../util.js"

export default function SearchRecord({n, isChild, history, setHistory, search, setTargetAudience, setMarketingChannels, setProductDescription, setSelectedAnalysis, setAnalysisEdited}) {
  const [opened, setOpened] = useState(false)
  const [typeOpened, setTypeOpened] = useState("delete")
  const [showingChildren, setShowingChildren] = useState(false)


  return <>
    <Popover open={opened} onOpenChange={(x) => {
      setOpened(x)
      setTypeOpened("normal")
    }} trigger="click" placement="right" content={
      <div className="pt-2 pb-1 w-full flex flex-col items-center pl-6 pr-6 overflow-hidden">
        <p className="text-sm w-36 text-center">{typeOpened == "delete" ? "Delete this analysis? You won't ever be able to restart it." : "Load analysis?"}</p>
        <Button size="sm" className="mt-2 mb-2" color={typeOpened == "delete" ? "failure" : "purple"} pill onClick={() => {
          if(typeOpened == "delete") {
            setOpened(false)
            setHistory(history.filter(x => x.id != search.id))
            credFetch(apiUrl + "api/remove-from-history", {
              method: "POST",
              body: JSON.stringify({analysis: search.id})
            }).then(async response => {
              const data = await response.json()
              console.log(data, "DELETED?")
            })
          } else {
            setOpened(false)
            setTargetAudience(search.targetAudience)
            setMarketingChannels(search.marketingChannels)
            setProductDescription(search.productDescription)
  
            setSelectedAnalysis(search.id)
            setAnalysisEdited(false)
          }
        }}>{typeOpened == "delete" ? "Delete" : "Load"}</Button>
      </div>
    }>
      <div key={search.id} className="pl-4 h-10 w-full hover:bg-slate-200 cursor-pointer flex pr-6 overflow-hidden relative search-record">
        {
          isChild
            ? <div style={{height: "14px", width: "14px"}} className="mr-3"/>
            : <button className="h-full shrink-0" onClick={(e) => {
              e.stopPropagation()
              setShowingChildren(!showingChildren)
            }}>
              {
                showingChildren
                  ? <FaAngleUp size="14" className="mr-3"/>
                  : <FaAngleDown size="14" className="mr-3"/>
              }
            </button>
        }
        <FaTrashCan className="absolute right-3 trash-button bg-slate-200 w-8 top-[50%] translate-y-[-50%]" style={{display: "none"}} onClick={(e) => {
          e.stopPropagation()
          setTypeOpened("delete")
          setOpened(true)
        }}/>
        <div className="h-full flex-col" style={{marginLeft: isChild ? "5px" : null}}>
          <p className="text-sm whitespace-nowrap truncate w-full select-none mb-[-2px] mt-[2px]">{isChild ? "Follow-up #" + n : search.productDescription}</p>
          <p className="text-xs font-medum text-slate-500 mt-[-2px]">{search.created_at ? convertTimeFromMS(new Date(Date.now() - search.created_at)) : "A few seconds"} ago</p>
        </div>
      </div>
    </Popover>
    {
      !isChild && showingChildren
        ? (
            search.children.length
              ? search.children.map((x, n) => {
                return <SearchRecord isChild={true} n={n+1} {...{history, setHistory, search: x, setTargetAudience, setMarketingChannels, setProductDescription, setSelectedAnalysis, setAnalysisEdited}}/>
              })
              : <p className="text-center font-medium text-sm mb-2">(Follow-up searches will show here)</p>
          ) 
        : null
    }
  </>
}

