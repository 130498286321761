import {useState} from "react"
import {getRating, getGaugeColor, numberWithCommas} from "../util.js"
import {FaCaretUp, FaCaretDown, FaCircleQuestion, FaUser, FaClock, FaRedditAlien, FaBook, FaXTwitter, FaDiscord, FaUsers, FaGlobe} from "react-icons/fa6"
import {Spinner} from "flowbite-react"
import { SimpleGauge } from "react-gauges"


export default function MarketingChannel({isPreview, mc, color, discordMinimumMembers, discordMaximumMembers, subredditsMaximumMembers, subredditsMinimumMembers, twitterMinimumFollowers, twitterMaximumFollowers, setReceiveTutorialPopup, twitterCommunityMinimumMembers, twitterCommunityMaximumMembers}) {
  const [opened, setOpened] = useState(false)
  
  let rating = getRating(mc, subredditsMinimumMembers, subredditsMaximumMembers, twitterMinimumFollowers, twitterMaximumFollowers, discordMinimumMembers, discordMaximumMembers, twitterCommunityMinimumMembers, twitterCommunityMaximumMembers)
  console.log(rating)
  //let color 




  if(mc.type == "subreddit") {
    
    const gaugeColor = getGaugeColor(rating)

    return <div style={{backgroundColor: color}} className={`w-full cursor-pointer ${opened ? null : "h-16"} border-black border-t border-opacity-30 flex pb-2 pt-2 pr-3 flex-col overflow-hidden`} onClick={isPreview ? () => {
      if(isPreview == "hidden") return
      alert("Please pay to access this feature. It's not available in preview. 😓")
    } : () => {setOpened(!opened)}}>
      {/* <img src={mc.icon ? "" : "./images/empty-subreddit.png"} className="h-12 ml-2" style={{aspectRatio: 1, objectFit: "contain"}}/> */}
      <div className="flex justify-between w-full">
        <div className="flex flex-row h-12">
          <div className="w-16 h-full flex items-center justify-center shrink-0">
            {
              mc.preview
                ? <Spinner size="md" color="purple"/>
                : mc.icon ? <img src={mc.icon} className="aspect-square h-10" style={{borderRadius: "100px"}}/> : <img src={"./images/empty-subreddit.png"} className="h-10"/>
            }
          </div>
          
          
          <div className="flex flex-col ml-2 justify-center">
            <div className="flex items-center">
              <img src={"./images/subreddit.png"} className="h-4 mr-2 shrink-0"/>
              <p className="font-medium text-sm">r/{mc.name}</p>
            </div>
            {
              mc.preview
                ? <p className="mt-2">Loading data...</p>
                : <p className="text-xs opacity-80 truncate">{mc.title}</p>
            }
          </div>
        </div>
        <div className="flex flex-row items-center" style={{width: "80px", flexShrink: 0, height: "50px", paddingLeft: "5px"}}>
          <div style={{transform: "scale(1.0)", width: "45px", height: "45px"}}>
            <SimpleGauge labelColor={gaugeColor} barColor={gaugeColor} labelTemplate="{value}" minLimit={0} maxLimit={100} value={Math.round(rating)} isTotal={true} barWidth={4} labelFontWeight="500" labelFontSize={"15px"} labelFontFamily={"Inter"}/>
          </div>
          {
            opened
              ? <FaCaretUp className="ml-4 mr-1"/>
              : <FaCaretDown className="ml-4 mr-1"/>
          }
        </div>
      </div>
      {
        opened && <div onClick={(e) => {e.stopPropagation()}} className="justify-between flex ml-16 pl-2 border-opacity-30 cursor-auto">
          <div>
            <p className="text-sm flex items-center">
              <FaCircleQuestion className="mr-2"/>
              <span className="font-semibold">Who is this for?</span>
              <span className="ml-2">{mc.followerPersona ? mc.followerPersona : "Loading member persona..."}</span>
            </p>
            <p className="text-sm flex items-center">
              <FaUser className="mr-2"/>
              <span className="font-semibold">Members count:</span>
              <span className="ml-2">{mc.members ? numberWithCommas(mc.members) : "Loading..."}</span>
            </p>
            <p className="text-sm flex items-center">
              <FaClock className="mr-2"/>
              <span className="font-semibold">Difficulty:</span>
              <span className="ml-2">generally easy</span>
            </p>
            <div className="mb-1 flex">
              <a target="_blank" href={`https://reddit.com/r/${mc.id}`} className="mr-6 w-fit flex text-sm font-medium items-center mt-3 hover:underline" color="purple" size="sm">
                <FaRedditAlien className="mr-2" style={{marginTop: "1px"}}/>
                <span>Go to subreddit</span>
              </a>
              <a target="_blank" className="select-none cursor-pointer w-fit flex text-sm font-medium items-center mt-3 hover:underline" color="blue" size="sm" onClick={() => {
                setReceiveTutorialPopup("reddit")
              }}>
                <FaBook className="mr-2" style={{marginTop: "1px"}}/>
                <span>Reddit marketing guide</span>
              </a>
            </div>
            
          </div>
          
        </div>
      }
      
    </div>
      
  } else if(mc.type == "twitter") {
    const gaugeColor = getGaugeColor(rating)

    return <div style={{backgroundColor: color}} className={`w-full cursor-pointer ${opened ? null : "h-16"} border-black border-t border-opacity-30 flex pb-2 pt-2 pr-3 flex-col overflow-hidden shrink-0`} onClick={isPreview ? () => {
      if(isPreview == "hidden") return
      alert("Please pay to access this feature. It's not available in preview. 😓")
    } : () => {setOpened(!opened)}}>
      {/* <img src={mc.icon ? "" : "./images/empty-subreddit.png"} className="h-12 ml-2" style={{aspectRatio: 1, objectFit: "contain"}}/> */}
      <div className="flex justify-between w-full">
        <div className="flex flex-row h-12">
          <div className="w-16 h-full flex items-center justify-center shrink-0">
            {
              mc.preview
                ? <Spinner size="md" color="purple"/>
                : mc.icon ? <img src={mc.icon} className="aspect-square h-10" style={{borderRadius: "100px"}}/> : null
            }
          </div>
          
          
          <div className="flex flex-col ml-2 justify-center">
            <div className="flex items-center">
              <img src={"./images/x.png"} className="h-3 mr-2 shrink-0"/>
              <p className="font-medium text-sm truncate">{mc.name}</p>
            </div>
            {
              mc.preview
                ? <p className="mt-2">Loading data...</p>
                : <p className="text-xs opacity-80 truncate line-clamp-1">{mc.description}</p>
            }
          </div>
        </div>
        <div className="flex flex-row items-center" style={{width: "80px", paddingLeft: "5px", flexShrink: 0, height: "50px"}}>
          <div style={{transform: "scale(1.0)", width: "45px", height: "45px"}}>
            <SimpleGauge labelColor={gaugeColor} barColor={gaugeColor} labelTemplate="{value}" minLimit={0} maxLimit={100} value={Math.round(rating)} isTotal={true} barWidth={4} labelFontWeight="500" labelFontSize={"15px"} labelFontFamily={"Inter"}/>
          </div>
          {
            opened
              ? <FaCaretUp className="ml-4 mr-1"/>
              : <FaCaretDown className="ml-4 mr-1"/>
          }
          
        </div>
      </div>
      <div>
        {
          opened && <div onClick={(e) => {e.stopPropagation()}} className="justify-between flex ml-16 pl-2 border-opacity-30 cursor-auto">
            <div>
              <p className="text-sm flex items-center">
                <FaCircleQuestion className="mr-2"/>
                <span className="font-semibold">Who is this for?</span>
                <span className="ml-2">{mc.followerPersona ? mc.followerPersona : "Loading member persona..."}</span>
              </p>
              <p className="text-sm flex items-center">
                <FaUser className="mr-2"/>
                <span className="font-semibold">Followers count:</span>
                <span className="ml-2">{mc.followersCount ? numberWithCommas(mc.followersCount) : "Loading..."}</span>
              </p>
              <p className="text-sm flex items-center">
                <FaClock className="mr-2"/>
                <span className="font-semibold">Difficulty:</span>
                <span className="ml-2">generally hard</span>
              </p>
              <div className="flex">
                <a target="_blank" href={mc.url} className="mr-6 w-fit flex text-sm font-medium items-center mt-3 hover:underline" color="purple" size="sm">
                  <FaXTwitter className="mr-2" style={{marginTop: "1px"}}/>
                  <span>Go to X</span>
                </a>
                <a target="_blank" className="select-none cursor-pointer w-fit flex text-sm font-medium items-center mt-3 hover:underline" color="blue" size="sm" onClick={() => {
                  setReceiveTutorialPopup("x")
                }}>
                  <FaBook className="mr-2" style={{marginTop: "1px"}}/>
                  <span>X marketing guide</span>
                </a>
              </div>
              
              {/* <Button target="_blank" pill as="a" href={mc.url} className="w-fit flex text-sm font-semibold items-center bg-purple-600 mt-3" color="purple" size="sm">
                <span>Go to X</span>
                <FaXTwitter className="h-3 ml-2" style={{marginTop: "4px"}}/>
              </Button> */}
            </div>
            
          </div>
        }
      </div>
    </div>
  } else if(mc.type == "discord") {
    const gaugeColor = getGaugeColor(rating)

    return <div style={{backgroundColor: color}} className={`w-full shrink-0 cursor-pointer ${opened ? null : "h-16"} border-black border-t border-opacity-30 flex pb-2 pt-2 pr-3 flex-col overflow-hidden`} onClick={isPreview ? () => {
      if(isPreview == "hidden") return
      alert("Please pay to access this feature. It's not available in preview. 😓")
    } : () => {setOpened(!opened)}}>
      {/* <img src={mc.icon ? "" : "./images/empty-subreddit.png"} className="h-12 ml-2" style={{aspectRatio: 1, objectFit: "contain"}}/> */}
      <div className="flex justify-between w-full">
        <div className="flex flex-row h-12">
          <div className="w-16 h-full flex items-center justify-center shrink-0">
            {
              mc.preview
                ? <Spinner size="md" color="purple"/>
                : mc.icon ? <img src={mc.icon} className="aspect-square h-10" style={{borderRadius: "100px"}}/> : <img src={"./images/empty-subreddit.png"}/>
            }
          </div>
          
          
          <div className="flex flex-col ml-2 justify-center">
            <div className="flex items-center">
              <img src={"./images/discord.png"} className="h-3 mr-2 shrink-0"/>
              <p className="font-medium text-sm truncate">{mc.name}</p>
            </div>
            {
              mc.preview
                ? <p className="mt-2">Loading data...</p>
                : <p className="text-xs opacity-80 truncate line-clamp-1">{mc.description}</p>
            }
          </div>
        </div>
        <div className="flex flex-row items-center" style={{width: "80px", paddingLeft: "5px", flexShrink: 0, height: "50px"}}>
          <div style={{transform: "scale(1.0)", width: "45px", height: "45px"}}>
            <SimpleGauge labelColor={gaugeColor} barColor={gaugeColor} labelTemplate="{value}" minLimit={0} maxLimit={100} value={Math.round(rating)} isTotal={true} barWidth={4} labelFontWeight="500" labelFontSize={"15px"} labelFontFamily={"Inter"}/>
          </div>
          {
            opened
              ? <FaCaretUp className="ml-4 mr-1"/>
              : <FaCaretDown className="ml-4 mr-1"/>
          }
        </div>
      </div>
      <div>
        {
          opened && <div onClick={(e) => {e.stopPropagation()}} className="justify-between flex ml-16 pl-2 border-opacity-30 cursor-auto">
            <div>
              <p className="text-sm flex items-center">
                <FaCircleQuestion className="mr-2"/>
                <span className="font-semibold">Who is this for?</span>
                <span className="ml-2">{mc.followerPersona ? mc.followerPersona : "Loading member persona..."}</span>
              </p>
              <p className="text-sm flex items-center">
                <FaUser className="mr-2"/>
                <span className="font-semibold">Members count:</span>
                <span className="ml-2">{mc.members ? numberWithCommas(mc.members) : "Loading..."}</span>
              </p>
              <p className="text-sm flex items-center">
                <FaClock className="mr-2"/>
                <span className="font-semibold">Difficulty:</span>
                <span className="ml-2">generaly easy</span>
              </p>
              {/* invite: */}
              <div className="flex">
                <a target="_blank" href={`https://discord.gg/${mc.vanityUrlCode}`} className="mr-6 w-fit flex text-sm font-medium items-center mt-3 hover:underline" color="purple" size="sm">
                  <FaDiscord className="mr-2" style={{marginTop: "1px"}}/>
                  <span>Go to Discord</span>
                </a>
                <a target="_blank" className="select-none cursor-pointer w-fit flex text-sm font-medium items-center mt-3 hover:underline" color="blue" size="sm" onClick={() => {
                  setReceiveTutorialPopup("x")
                }}>
                  <FaBook className="mr-2" style={{marginTop: "1px"}}/>
                  <span>Discord marketing guide</span>
                </a>
              </div>
              
              {/* <div className="flex items-center mt-3">
                <Button disabled target="_blank" pill as="a" className="grayscale w-fit flex text-sm font-semibold items-center bg-purple-600" color="purple" size="sm">
                  <span>Go to Discord</span>
                  <FaDiscord className="h-3 ml-2" style={{marginTop: "4px"}}/>
                </Button>
                <p className="ml-3">Unfortunately, you have to be invited to this server to join 😥 Try to find the link online</p>
              </div> */}
              
            </div>
            
          </div>
        }
      </div>
    </div>
      
  } else if(mc.type == "twitter-community") {
    const gaugeColor = getGaugeColor(rating)

    return <div style={{backgroundColor: color}} className={`w-full shrink-0 cursor-pointer ${opened ? null : "h-16"} border-black border-t border-opacity-30 flex pb-2 pt-2 pr-3 flex-col overflow-hidden`} onClick={isPreview ? () => {
      if(isPreview == "hidden") return
      alert("Please pay to access this feature. It's not available in preview. 😓")
    } : () => {setOpened(!opened)}}>
      {/* <img src={mc.icon ? "" : "./images/empty-subreddit.png"} className="h-12 ml-2" style={{aspectRatio: 1, objectFit: "contain"}}/> */}
      <div className="flex justify-between w-full">
        <div className="flex flex-row h-12">
          <div className="w-16 h-full flex items-center justify-center shrink-0">
            {
              mc.preview
                ? <Spinner size="md" color="purple"/>
                : mc.picture ? <img src={mc.picture} className="aspect-square h-10 object-cover" style={{borderRadius: "100px"}}/> : null
            }
          </div>
          
          
          <div className="flex flex-col ml-2 justify-center">
            <div className="flex items-center">
              <img src={"./images/x.png"} className="h-3 shrink-0"/>
              <FaUsers className="mr-2 ml-2" />
              <p className="font-medium text-sm truncate">{mc.name}</p>
            </div>
            {
              mc.preview
                ? <p className="mt-2">Loading data...</p>
                : <p className="text-xs opacity-80 truncate line-clamp-1">{mc.description}</p>
            }
          </div>
        </div>
        <div className="flex flex-row items-center" style={{width: "80px", paddingLeft: "5px", flexShrink: 0, height: "50px"}}>
          <div style={{transform: "scale(1.0)", width: "45px", height: "45px"}}>
            <SimpleGauge labelColor={gaugeColor} barColor={gaugeColor} labelTemplate="{value}" minLimit={0} maxLimit={100} value={Math.round(rating)} isTotal={true} barWidth={4} labelFontWeight="500" labelFontSize={"15px"} labelFontFamily={"Inter"}/>
          </div>
          {
            opened
              ? <FaCaretUp className="ml-4 mr-1"/>
              : <FaCaretDown className="ml-4 mr-1"/>
          }
        </div>
      </div>
      <div>
        {
          opened && <div onClick={(e) => {e.stopPropagation()}} className="justify-between flex ml-16 pl-2 border-opacity-30 cursor-auto">
            <div>
              <p className="text-sm flex items-center">
                <FaCircleQuestion className="mr-2"/>
                <span className="font-semibold">Who is this for?</span>
                <span className="ml-2">{mc.followerPersona ? mc.followerPersona : "Loading member persona..."}</span>
              </p>
              <p className="text-sm flex items-center">
                <FaUser className="mr-2"/>
                <span className="font-semibold">Members count:</span>
                <span className="ml-2">{mc.members ? numberWithCommas(mc.members) : "Loading..."}</span>
              </p>
              <p className="text-sm flex items-center">
                <FaClock className="mr-2"/>
                <span className="font-semibold">Difficulty:</span>
                <span className="ml-2">generaly easy</span>
              </p>
              {/* invite: */}
              <div className="flex">
                <a target="_blank" href={`https://x.com/i/communities/${mc.id}`} className="mr-6 w-fit flex text-sm font-medium items-center mt-3 hover:underline" color="purple" size="sm">
                  <FaXTwitter className="mr-2" style={{marginTop: "1px"}}/>
                  <span>Go to X</span>
                </a>
                <a target="_blank" className="select-none cursor-pointer w-fit flex text-sm font-medium items-center mt-3 hover:underline" color="blue" size="sm" onClick={() => {
                  setReceiveTutorialPopup("x")
                }}>
                  <FaBook className="mr-2" style={{marginTop: "1px"}}/>
                  <span>X community marketing guide</span>
                </a>
              </div>
              
              {/* <div className="flex items-center mt-3">
                <Button disabled target="_blank" pill as="a" className="grayscale w-fit flex text-sm font-semibold items-center bg-purple-600" color="purple" size="sm">
                  <span>Go to Discord</span>
                  <FaDiscord className="h-3 ml-2" style={{marginTop: "4px"}}/>
                </Button>
                <p className="ml-3">Unfortunately, you have to be invited to this server to join 😥 Try to find the link online</p>
              </div> */}
              
            </div>
            
          </div>
        }
      </div>
    </div>
  } else if(mc.type == "blog") {
    const gaugeColor = getGaugeColor(rating)

    return <div style={{backgroundColor: color}} className={`w-full shrink-0 cursor-pointer ${opened ? null : "h-16"} border-black border-t border-opacity-30 flex pb-2 pt-2 pr-3 flex-col overflow-hidden`} onClick={isPreview ? () => {
      if(isPreview == "hidden") return
      alert("Please pay to access this feature. It's not available in preview. 😓")
    } : () => {setOpened(!opened)}}>
      {/* <img src={mc.icon ? "" : "./images/empty-subreddit.png"} className="h-12 ml-2" style={{aspectRatio: 1, objectFit: "contain"}}/> */}
      <div className="flex justify-between w-full">
        <div className="flex flex-row h-12">
          <div className="w-16 h-full flex items-center justify-center shrink-0">
            {
              mc.preview
                ? <Spinner size="md" color="purple"/>
                : mc.icon ? <img src={mc.icon} className="aspect-square h-10 object-cover" style={{borderRadius: "100px"}}/> : null
            }
          </div>
          
          
          <div className="flex flex-col ml-2 justify-center">
            <div className="flex items-center">
              <FaGlobe className="mr-2" />
              <p className="font-medium text-sm truncate">{mc.name}</p>
            </div>
            {
              mc.preview
                ? <p className="mt-2">Loading data...</p>
                : <p className="text-xs opacity-80 truncate line-clamp-1">{mc.description}</p>
            }
          </div>
        </div>
        <div className="flex flex-row items-center" style={{width: "80px", paddingLeft: "5px", flexShrink: 0, height: "50px"}}>
          <div style={{transform: "scale(1.0)", width: "45px", height: "45px"}}>
            <SimpleGauge labelColor={gaugeColor} barColor={gaugeColor} labelTemplate="{value}" minLimit={0} maxLimit={100} value={Math.round(rating)} isTotal={true} barWidth={4} labelFontWeight="500" labelFontSize={"15px"} labelFontFamily={"Inter"}/>
          </div>
          {
            opened
              ? <FaCaretUp className="ml-4 mr-1"/>
              : <FaCaretDown className="ml-4 mr-1"/>
          }
        </div>
      </div>
      <div>
        {
          opened && <div onClick={(e) => {e.stopPropagation()}} className="justify-between flex ml-16 pl-2 border-opacity-30 cursor-auto">
            <div>
              <p className="text-sm flex items-center">
                <FaCircleQuestion className="mr-2"/>
                <span className="font-semibold">Who is this for?</span>
                <span className="ml-2">{mc.followerPersona ? mc.followerPersona : "Loading member persona..."}</span>
              </p>
              <p className="text-sm flex items-center">
                <FaUser className="mr-2"/>
                <span className="font-semibold">Visitors count:</span>
                <span className="ml-2">???</span>
              </p>
              <p className="text-sm flex items-center">
                <FaClock className="mr-2"/>
                <span className="font-semibold">Difficulty:</span>
                <span className="ml-2">generally easy</span>
              </p>
              {/* invite: */}
              <div className="flex">
                <a target="_blank" href={mc.url} className="mr-6 w-fit flex text-sm font-medium items-center mt-3 hover:underline" color="purple" size="sm">
                  <FaGlobe className="mr-2" style={{marginTop: "1px"}}/>
                  <span>Go to blog</span>
                </a>
                <a target="_blank" className="select-none cursor-pointer w-fit flex text-sm font-medium items-center mt-3 hover:underline" color="blue" size="sm" onClick={() => {
                  setReceiveTutorialPopup("x")
                }}>
                  <FaBook className="mr-2" style={{marginTop: "1px"}}/>
                  <span>X community marketing guide</span>
                </a>
              </div>
              
              {/* <div className="flex items-center mt-3">
                <Button disabled target="_blank" pill as="a" className="grayscale w-fit flex text-sm font-semibold items-center bg-purple-600" color="purple" size="sm">
                  <span>Go to Discord</span>
                  <FaDiscord className="h-3 ml-2" style={{marginTop: "4px"}}/>
                </Button>
                <p className="ml-3">Unfortunately, you have to be invited to this server to join 😥 Try to find the link online</p>
              </div> */}
              
            </div>
            
          </div>
        }
      </div>
    </div>
  }
}
