import {Button, Popover} from "flowbite-react"
import {credFetch, apiUrl} from "../util.js"

export default function Topbar({auth, setAuth, setStage, showCredits}) {
  return <div className="flex justify-between w-full">
    <div className="flex items-center">
      <a href="./" aria-label="Go to homepage">
        <img src={"/images/logo-full.png"} alt="TrafficScout logo" className="h-10"/>
      </a>
      {
        showCredits && auth
          ? <p className="ml-4">
            • <span className="ml-3"><span className="font-bold">{auth?.credits_count === undefined ? "?" : auth.credits_count}</span> credits left</span>
          </p>
          : null
      }
    </div>

     
    <div className="flex">
      <a href="/pricing" className="cursor-pointer h-full mr-5 pl-2 pr-2 text-black flex items-center" onClick={(e) => {
        e.preventDefault()
        setStage("pricing")
      }}>
        Pricing
      </a>
      {
        auth
          ? <>
            <a href="/dashboard" onClick={(e) => {
              e.preventDefault()
              setStage("dashboard")
            }} className="cursor-pointer h-full pl-2 pr-2 text-black flex items-center">
              Dashboard
            </a>
            <Popover trigger="click" placement="bottom" content={<div className="pl-3 pr-3 flex flex-col items-center">
              <p className="text-center mt-2 font-medium text-lg">
                Welcome, {auth.given_name || auth.name || auth.email}
              </p>
              <Button size="sm" className="mt-2 mb-2" color="red" pill onClick={() => {
                credFetch(apiUrl + "api/logout", {
                  method: "GET",
                })
                setAuth(null)
                window.location.reload()
              }}>Log out</Button>
            </div>}>
              <img src={auth.picture} className="cursor-pointer ml-5 h-10 aspect-square rounded-full bg-white border border-black"/>
            </Popover>
          </>
          : <Button pill gradientDuoTone="purpleToPink" className="font-bold pl-2 pr-2" onClick={() => {
            setStage("start")
          }}>
            START NOW
          </Button>
      }
      
    </div>
  </div>
}

