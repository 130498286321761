import {useState} from "react"
import {Modal, Button, Dropdown} from "flowbite-react"
import {CSVLink} from "react-csv"
import {getRating} from "../util.js"

export default function ExportModal({exportModalOpened, setExportModalOpened, marketingChannels, subredditsMinimumMembers, subredditsMaximumMembers, twitterMinimumFollowers, twitterMaximumFollowers, discordMinimumMembers, discordMaximumMembers, twitterCommunityMinimumMembers, twitterCommunityMaximumMembers}) {
  const [selectedFileType, setSelectedFileType] = useState("CSV")

  let parsedMc = marketingChannels.map(mc => {
    let url = mc.url
    if(mc.type == "subreddit") {
      url = `https://reddit.com/r/${mc.id}`
    } else if(mc.type == "discord") {
      url = `https://discord.gg/${mc.vanityUrlCode}`
    } else if(mc.type == "twitter-community") {
      url = `https://x.com/i/communities/${mc.id}`
    }

    return {
      type: mc.type,
      id: mc.id,
      name: mc.username || mc.name,
      reach: mc.members || mc.followersCount,
      description: mc.description,
      title: mc.title,
      url: url,
      picture: mc.icon || mc.picture,
      canDm: mc.canDm,
      keywords: mc.keywords,
      topic: mc.topic,
      nsfw: mc.nsfw,
      joinPolicy: mc.joinPolicy,
      rating: getRating(mc, subredditsMinimumMembers, subredditsMaximumMembers, twitterMinimumFollowers, twitterMaximumFollowers, discordMinimumMembers, discordMaximumMembers, twitterCommunityMinimumMembers, twitterCommunityMaximumMembers)
    }
  })

  return <Modal show={exportModalOpened} size="lg" popup onClose={() => {
    setExportModalOpened(false)
  }}>
    <Modal.Header className="font-medium pl-3">
      Export
    </Modal.Header>
    <div className="flex flex-col items-center">
      <p>
        Select the type of file you want to export:
      </p>

      <Dropdown
        inline
        label={selectedFileType}
        className="w-fit"
      >
        <Dropdown.Item onClick={() => {
          setSelectedFileType("CSV")
        }}>CSV</Dropdown.Item>
        <Dropdown.Item onClick={() => {
          setSelectedFileType("JSON")
        }}>JSON</Dropdown.Item>
      </Dropdown>

      {
        selectedFileType == "CSV"
          ? <CSVLink data={parsedMc} filename="marketing_channels.csv" className="w-fit">
            <Button className="success w-fit mb-4 mt-6" pill color="purple">Download CSV</Button>
          </CSVLink>
          : <Button className="success w-fit mb-4 mt-6" pill color="purple" onClick={() => {
            const json = JSON.stringify({
              marketingChannels: parsedMc
            }, null, "\t")

            const blob = new Blob([json], {type: "application/json"})
            const url = URL.createObjectURL(blob)
            const link = document.createElement("a")
            link.href = url
            link.download = "marketing_channels.json"
            document.body.appendChild(link)
            link.click()
          }}>Download JSON</Button>
      }
      
      {/* <Button className="success w-fit mb-4 mt-6" pill color="purple" onClick={() => {
        
      }}>Download {selectedFileType}</Button> */}

    </div>
  </Modal>
}