import {useEffect} from "react"
import {Modal, FloatingLabel, Checkbox, Label, Button} from "flowbite-react"
import {capitalizeFirstLetter, validateEmail, credFetch, apiUrl} from "../util.js"

export default function ReceiveTutorialPopup({setReceiveTutorialPopup, receiveTutorialPopup, tutorialEmail, setTutorialEmail, auth}) {
  useEffect(() => {
    if(auth?.email) {
      setTutorialEmail(auth.email)
    }
  }, [receiveTutorialPopup, auth])

  return <Modal show={!!receiveTutorialPopup} size="lg" popup onClose={() => {
    setReceiveTutorialPopup(null)
  }}>
    <Modal.Header className="font-medium">
      <p style={{marginLeft: "10px", marginTop: "2px", fontSize: "19px"}}>
        {typeof receiveTutorialPopup == "string" ? capitalizeFirstLetter(receiveTutorialPopup || "a") : "Marketing"} guide
      </p>
    </Modal.Header>
    <p className="text-center w-full mt-2">
      Input your email, guide will be in your inbox shortly 🙂
    </p>
    <div className="w-full pl-5 pr-5 mt-4 mb-6">
      
      <FloatingLabel variant="outlined" label="Email" width={"100%"} style={{width: "100%"}} defaultValue={tutorialEmail} onChange={(v) => {
        setTutorialEmail(v.target.value)
      }}/>
      <div className="flex items-center">
        <Checkbox id="addToNewsletter" defaultChecked className="mr-3" color="purple" />
        <Label htmlFor="addToNewsletter" className="flex">
          Add me to newsletter to receive marketing news, tips & more
        </Label>
      </div>
      
      <Button gradientDuoTone="purpleToPink" className="m-auto mt-3" onClick={() => {
        let addToNewsletter = document.getElementById("addToNewsletter").checked 

        if(!validateEmail(tutorialEmail)) {
          alert("This isn't a valid email. Please check again.")
          return
        }

        credFetch(apiUrl + "api/send-guide", {
          method: "POST",
          body: JSON.stringify({
            email: tutorialEmail,
            addToNewsletter,
            type: receiveTutorialPopup
          })
        }).then(response => {
          response.json().then(() => {
            setReceiveTutorialPopup(null)
          })
        })

        




      }}>Submit</Button>
    </div>
  </Modal>
}
