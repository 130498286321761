import { PiSidebarSimpleFill } from "react-icons/pi";
import SearchRecord from "./SearchRecord.js"
import {Button} from "flowbite-react"
import { IoAddCircle } from "react-icons/io5";

export default function Sidebar({sidebarOpened, setSidebarOpened, history, setHistory, setTargetAudience, setMarketingChannels, setProductDescription, selectedAnalysis, setSelectedAnalysis, analysisEdited, setAnalysisEdited}) {
  let parsedHistory = []

  history.forEach(x => {
    if(x.original === true || x.original === undefined) {
      parsedHistory.push({
        ...x,
        children: []
      })
    }
  })
  history.forEach(x => {
    if(x.original === false) {
      const found = parsedHistory.find(y => y.id == x.originalAnalysis)
      if(found) {
        found.children.push(x)
      } else {
        console.log("whoopsies")
      }
      
    }
  })

  console.log(parsedHistory)


  
  return <div className="shrink-0 box-border overflow-hidden nowrap flex flex-col bg-slate-100 h-full" style={{transition: "width .5s", width: sidebarOpened ? "300px" : "0px"}}>
    <div style={{minWidth: "300px", overflow: "hidden", display: 'flex', flexDirection: "column", height: "100%"}}>
      <div className="flex items-center mt-6 pl-6 pr-6 mb-3">
        <PiSidebarSimpleFill color="#3a3c57" className="cursor-pointer w-8 h-8 p-1 rounded-md bg-slate-200" onClick={() => {
          setSidebarOpened(false)
        }}/>
        <p className="font-medium text-lg ml-2 mt-1">History</p>
      </div>
      <div className="flex flex-col grow" style={{flexGrow: 1}}>
        {
          parsedHistory && parsedHistory.length
            ? parsedHistory.map(search => {
              return <SearchRecord isChild={false} {...{history, setHistory, search, setTargetAudience, setMarketingChannels, setProductDescription, selectedAnalysis, setSelectedAnalysis, analysisEdited, setAnalysisEdited}}/>
            })
            : <p className="text-sm mt-3 ml-6 mr-6">Previous marketing channel searches will appear here.</p>
        }
      </div>
      <Button color="purple" pill className="mb-6 ml-6 mr-6" onClick={() => {
        setSelectedAnalysis(null)
        setMarketingChannels([])
        setProductDescription("")
        setTargetAudience(null)
        document.getElementById("product-description-input").focus()
      }}>
        <div className="flex items-center">
          <IoAddCircle size={15} className="mr-2"/>

          <p>Analyse a new product</p>
        </div>
        
      </Button>
      
      
    </div>
    
  </div>
}